import {parseBigInt} from '@rockawayxlabs/observatory-utils';

const decimalFormatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  maximumFractionDigits: 0,
});

const floatFormatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

const percentageFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
  maximumFractionDigits: 0,
});

const dynamicPercentageFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

const fractionalPercentageFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

const scoreDifferenceFormatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
  signDisplay: 'always',
});

const usdFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 6,
});

type FormattableValue = {toNumber(): number} | number | bigint | string;

export class NumberFormat {
  private static toFormattable<T extends FormattableValue>(
    value: T
  ): T extends string ? number | bigint | undefined : number | bigint {
    if (typeof value === 'number' || typeof value === 'bigint') {
      return value;
    }
    if (typeof value === 'object') {
      return value.toNumber();
    }

    const float = parseFloat(value);
    if (!isNaN(float) && isFinite(float)) {
      return float;
    }

    return parseBigInt(value) as T extends string ? number | bigint | undefined : number | bigint;
  }

  private static conditionalFormat<T extends FormattableValue>(
    formatter: Intl.NumberFormat,
    value: T
  ): T extends string ? string | undefined : string {
    const formattable = this.toFormattable(value);
    if (typeof formattable === 'undefined') {
      return undefined as T extends string ? string | undefined : string;
    }
    return formatter.format(formattable);
  }

  public static decimal<T extends FormattableValue>(value: T) {
    return this.conditionalFormat(decimalFormatter, value);
  }

  public static float<T extends FormattableValue>(value: T) {
    return this.conditionalFormat(floatFormatter, value);
  }

  public static percentage<T extends FormattableValue>(value: T) {
    return this.conditionalFormat(percentageFormatter, value);
  }

  public static dynamicPercentage<T extends FormattableValue>(value: T) {
    return this.conditionalFormat(dynamicPercentageFormatter, value);
  }

  public static fractionalPercentage<T extends FormattableValue>(value: T) {
    return this.conditionalFormat(fractionalPercentageFormatter, value);
  }

  public static usd<T extends FormattableValue>(value: T) {
    return this.conditionalFormat(usdFormatter, value);
  }

  public static scoreDifference<T extends FormattableValue>(value: T) {
    return this.conditionalFormat(scoreDifferenceFormatter, value);
  }

  public static score<T extends FormattableValue>(value: T) {
    return this.conditionalFormat(percentageFormatter, value)?.replace('%', '') as T extends string
      ? string | undefined
      : string;
  }

  public static tokenAmount<T extends FormattableValue>(value: T, tokenSymbol: string) {
    const decimal = this.decimal(value);
    if (typeof decimal === 'undefined') {
      return undefined as T extends string ? string | undefined : string;
    }
    return `${decimal} ${tokenSymbol}`;
  }

  public static seconds<T extends FormattableValue>(value: T) {
    const float = this.float(value);
    if (typeof float === 'undefined') {
      return undefined as T extends string ? string | undefined : string;
    }
    return `${float}s`;
  }
}
